import React from 'react';
import { useSelector } from 'react-redux';

import { authUtils, toggleTokenStorageKey } from 'utils';

import { MainLayout } from 'layouts';
import { ToggleObject } from 'types';

import { RootState } from 'Reducers/contactReducer';
import { DashboardOld } from './DashboardOld';
import { DashboardRedesign } from './DashboardRedesign';

export const DashboardView: React.FC = () => {
  const toggleReduxValue: ToggleObject = useSelector(
    (state: RootState) => state.toggleValue
  );
  const toggleValue = toggleReduxValue.default
    ? JSON.parse(authUtils.getToken(toggleTokenStorageKey))
    : JSON.parse(toggleReduxValue.value);
  const user = authUtils.getLoggedInUser();
  return (
    <MainLayout>
      {!toggleValue && <DashboardRedesign id={user.id} name={user.name} />}
      {toggleValue && <DashboardOld role={user.role} />}
    </MainLayout>
  );
};
