/*
 * Copyright 2024-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import React from 'react';
import { Icon, MenuItem } from 'semantic-ui-react';
import { notifier } from 'utils';

import { TopologyDetails } from 'types';

import { topologyDetailsHtml } from './TopologyDetailsHtml';
import { topologyDetailsText } from './TopologyDetailsText';

export function CopyTopologyDetailsMenuItem(props: {
  topology: TopologyDetails;
}) {
  const handleCopy = (): void => {
    if (window.isSecureContext) {
      const clipboardItem = new ClipboardItem({
        'text/plain': new Blob([topologyDetailsText(props.topology)], {
          type: 'text/plain'
        }),
        'text/html': new Blob([topologyDetailsHtml(props.topology)], {
          type: 'text/html'
        })
      });
      navigator.clipboard.write([clipboardItem]).then(() => {
        notifier.success({
          message: 'Topology details copied to clipboard'
        });
      });
    }
  };

  return (
    <MenuItem role={'button'} onClick={handleCopy}>
      <Icon name='copy outline' />
      Copy topology details
    </MenuItem>
  );
}
