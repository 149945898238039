/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

// TODO(roman): check variable names with Claire
// + design_type
// + is_evpn
// + has_rack_local
// + clos_single_rack_count
// + clos_dual_rack_count
// + 5_stage_pod_count
// !? clos_avg_generic_systems_per_rack_count - clarify if ok to modify for 3-stage and 5-stage
// + rack_local_vns_count
// + rack_local_endpoint_count
// + evpn_rz_count
// + vxlan_vn_count_total
// + vxlan_endpoint_count
// !? mac_entries_per_switch_count - derived from other values (???)
// ! IBA VMWare? - renamed to iba_vmware
// ! IBA Scale - renamed to iba_scale

export const survey = {
  logoPosition: 'right',
  pageNextText: 'Continue',
  pages: [
    {
      name: 'project_name',
      elements: [
        {
          type: 'text',
          name: 'project_name',
          isRequired: true,
          title: 'Name the project'
        },
        {
          type: 'comment',
          name: 'project_description',
          isRequired: false,
          title: 'Project description',
          description:
            'Add any meaningful details about the project to help the Product Sizing ' +
            'team better understand the target environment.'
        },
        {
          type: 'radiogroup',
          name: 'testing',
          isRequired: true,
          choices: [
            {
              text: 'just testing',
              value: true
            },
            {
              text: 'actual request',
              value: false
            }
          ],
          title: 'Is this an actual request, or are you just testing the tool?'
        }
      ],
      title: 'Give a name to your project',
      description:
        'You can use this form to submit the scaling dimensions of a given type of ' +
        'fabric. If your project includes multiple fabrics with different type of ' +
        'scales, add additional fabric types by submitting another response using ' +
        'the same fabric name.'
    },
    {
      name: 'survey',
      elements: [
        {
          type: 'paneldynamic',
          name: 'all_fabrics',
          title: 'General Information about this type of Blueprint',
          panelAddText: 'Add new fabric type',
          templateTabTitle: '{panel.design_type}',
          renderMode: 'tab',
          panelCount: 1,
          maxPanelCount: 20,
          confirmDelete: false,
          templateElements: [
            {
              type: 'text',
              name: 'number_of_fabrics',
              title:
                'How many fabrics of this type and scale will be deployed?',
              description:
                'Ex: "1" if there\'s only one fabric of this type and scale, enter ' +
                '"20" if there will be 20 blueprints of the type of design and scale.',
              isRequired: true,
              min: 1,
              max: 1000,
              step: 1,
              defaultValue: 1,
              validators: [{ type: 'numeric', text: 'Value must be a number' }]
            },
            {
              type: 'radiogroup',
              name: 'design_type',
              title: 'What is the design of this type of fabrics?',
              isRequired: true,
              choices: [
                {
                  value: 'threeStageFabric',
                  text: '3-stage fabric'
                },
                {
                  value: 'fiveStageFabric',
                  text: '5-stage fabric'
                },

                {
                  value: 'freeformFabric',
                  text: 'freeform fabric'
                }
              ]
            },
            {
              type: 'text',
              name: 'managed_devices_count',
              title:
                'How many total Apstra managed devices will be deployed in this fabric?',
              description:
                'Enter the number of managed devices in the fabric, ' +
                'for example if there are 16 leaf devices ' +
                'and 4 spines please enter "20" total devices.',
              isRequired: true,
              min: 1,
              max: 1000,
              step: 1,
              defaultValue: 1,
              validators: [{ type: 'numeric', text: 'Value must be a number' }]
            },
            {
              visibleIf: `{panel.design_type} = 'threeStageFabric'`,
              type: 'radiogroup',
              title:
                'How many single-leaf racks will be deployed in this fabric?',
              name: 'clos_single_rack_count',
              isRequired: true,
              choices: [
                'None (all racks are dual-leafs)',
                'Between 1 and 5',
                'Between 6 and 10',
                'Between 11 and 20',
                'Between 21 and 30',
                'More than 30'
              ]
            },
            {
              visibleIf: `{panel.design_type} = 'threeStageFabric'`,
              type: 'radiogroup',
              name: 'clos_dual_rack_count',
              title:
                'How many dual-leaf racks will be deployed in this fabric?',
              isRequired: true,
              choices: [
                'None (all racks are single-leafs)',
                'Between 1 and 5',
                'Between 6 and 10',
                'Between 11 and 20',
                'Between 21 and 30',
                'More than 30'
              ]
            },
            {
              visibleIf: `{panel.design_type} = 'threeStageFabric'`,
              type: 'radiogroup',
              name: '3_stage_clos_avg_generic_systems_per_rack_count',
              title:
                'On average, what is the size of a rack (number of servers per rack)',
              isRequired: true,
              choices: [
                'Between 1 and 10',
                'Between 11 and 20',
                'Between 21 and 30',
                'Between 31 and 40'
              ]
            },
            {
              visibleIf: `{panel.design_type} = 'threeStageFabric'`,
              type: 'radiogroup',
              name: 'threeStageFabricType',
              title: 'Is your fabric EVPN/VXLAN or IP only?',
              isRequired: true,
              choices: ['EVPN/VXLAN', 'IP only']
            },
            {
              visibleIf: `{panel.design_type} = 'fiveStageFabric'`,
              type: 'radiogroup',
              name: '5_stage_pod_count',
              title: 'What is the number of PODs in your 5-stage fabric?',
              isRequired: true,
              choices: ['1', '2', 'Between 2 and 4', 'Between 5 and 8']
            },
            {
              type: 'radiogroup',
              name: '5_stage_clos_single_rack_count',
              visibleIf: `{panel.design_type} = 'fiveStageFabric'`,
              title:
                'How many single-leaf racks will be deployed in each POD (on average)?',
              isRequired: true,
              choices: [
                'None (all racks are dual-leafs)',
                'Between 1 and 5',
                'Between 6 and 10',
                'Between 11 and 20',
                'Between 21 and 30',
                'More than 30'
              ]
            },
            {
              type: 'radiogroup',
              name: '5_stage_clos_dual_rack_count',
              visibleIf: `{panel.design_type} = 'fiveStageFabric'`,
              title:
                'How many dual-leaf racks will be deployed in each POD (on average)?',
              isRequired: true,
              choices: [
                'None (all racks are single-leaf)',
                'Between 1 and 5',
                'Between 6 and 10',
                'Between 11 and 20',
                'Between 21 and 30',
                'More than 30'
              ]
            },
            {
              type: 'radiogroup',
              visibleIf: `{panel.design_type} = 'fiveStageFabric'`,
              name: '5_stage_clos_avg_generic_systems_per_rack_count',
              title:
                'On average, what is the size of a rack (number of servers per rack)',
              isRequired: true,
              choices: [
                'Between 1 and 10',
                'Between 11 and 20',
                'Between 21 and 30',
                'Between 31 and 40'
              ]
            },
            {
              type: 'radiogroup',
              visibleIf: `{panel.design_type} = 'fiveStageFabric'`,
              name: 'fiveStageFabricType',
              title: 'Is your fabric EVPN/VXLAN or IP only?',
              isRequired: true,
              choices: ['EVPN/VXLAN', 'IP only']
            },
            {
              visibleIf:
                `({panel.design_type} = 'threeStageFabric' and {panel.threeStageFabricType} = 'EVPN/VXLAN') ` +
                `or ({panel.design_type} = 'fiveStageFabric' and {panel.fiveStageFabricType} = 'EVPN/VXLAN')`,
              type: 'radiogroup',
              name: 'evpn_rz_count',
              title:
                'How many EVPN VRF/Apstra Routing Zones do you anticipate deploying ' +
                'in this fabric? If this is a 5-stage fabric, provide an average number per POD.',
              choices: ['About 5', 'About 10', 'About 50', 'About 100']
            },
            {
              visibleIf:
                `({panel.design_type} = 'threeStageFabric' and {panel.threeStageFabricType} = 'EVPN/VXLAN') ` +
                `or ({panel.design_type} = 'fiveStageFabric' and {panel.fiveStageFabricType} = 'EVPN/VXLAN')`,
              type: 'radiogroup',
              name: 'vxlan_vn_count_total',
              title:
                'How many VXLAN Virtual Networks do you anticipate deploying in this ' +
                'fabric? If this is a 5-stage fabric, provide an average number per POD.',
              choices: [
                'Less than 50',
                'A few hundreds',
                'More than 500 but less than 1K',
                'More than 1K but less than 2K',
                'More than 2K but less than 4K'
              ]
            },
            {
              visibleIf:
                `({panel.design_type} = 'threeStageFabric' and {panel.threeStageFabricType} = 'EVPN/VXLAN') ` +
                `or ({panel.design_type} = 'fiveStageFabric' and {panel.fiveStageFabricType} = 'EVPN/VXLAN')`,

              type: 'radiogroup',
              name: 'vxlan_endpoint_count',
              title:
                'On average, how many servers or "IP/MAC" endpoints will be connected ' +
                'to the same Virtual Network? Or, asked differently how many ' +
                'server-facing interfaces will each virtual network be stretched ' +
                'across (provide an average)?',
              isRequired: true,
              choices: [
                'About 10 endpoints per Virtual Network',
                'About 50 endpoints per Virtual Network',
                'About 100 endpoints per Virtual Network',
                'About 200 endpoints per Virtual Network'
              ]
            },
            {
              visibleIf:
                `({panel.design_type} = 'threeStageFabric' and {panel.threeStageFabricType} = 'IP only') ` +
                `or ({panel.design_type} = 'fiveStageFabric' and {panel.fiveStageFabricType} = 'IP only')`,
              type: 'radiogroup',
              name: 'has_rack_local',
              title: 'Will you deploy rack local VLANs?',
              isRequired: true,
              choices: ['Yes', 'No']
            },
            {
              visibleIf:
                `(({panel.design_type} = 'threeStageFabric' and {panel.threeStageFabricType} = 'IP only') ` +
                `or ({panel.design_type} = 'fiveStageFabric' and {panel.fiveStageFabricType} = 'IP only')) ` +
                `and {panel.has_rack_local} = 'Yes'`,
              type: 'radiogroup',
              name: 'rack_local_vns_count',
              title:
                'How many rack local VLANS will you deploy? If this is a 5-stage ' +
                'fabric, provide the average number per POD.',
              choices: ['About 10', 'About 20', 'About 50', 'About 100']
            },
            {
              visibleIf:
                `({panel.design_type} = 'threeStageFabric' and {panel.threeStageFabricType} = 'IP only') ` +
                `or ({panel.design_type} = 'fiveStageFabric' and {panel.fiveStageFabricType} = 'IP only')` +
                `and {panel.has_rack_local} = 'Yes'`,
              type: 'radiogroup',
              name: 'rack_local_endpoint_count',
              title:
                'On average, how many servers or "IP/MAC" endpoints will be ' +
                'connected to the same Local VLAN? Or, asked differently how many ' +
                'server-facing interfaces will each local VLAN be stretched across ' +
                '(provide an average)?',
              isRequired: true,
              choices: [
                'About 10 servers',
                'About 20 servers',
                'About 30 servers',
                'About 40 servers'
              ]
            },
            {
              visibleIf: `{panel.design_type} != undefined and {panel.design_type} != 'freeformFabric'`,
              type: 'radiogroup',
              name: 'iba_vmware',
              title:
                'Do you plan to purchase the Apstra/VMWare integration (enable ' +
                'VM visibility and virtual infra anomalies detection)',
              choices: ['Yes', 'No', 'Maybe in the future']
            },
            {
              visibleIf: `{panel.design_type} != undefined`,
              type: 'radiogroup',
              name: 'iba_scale',
              title:
                'Beyond using the pre-defined analytics, do you plan to develop ' +
                'a lot of custom probes yourself?',
              choices: [
                'I will mainly use pre-defined analytics only',
                'I will use pre-defined analytics but also plan to develop custom probes',
                `I don't know yet`
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'project_results',
      elements: [
        {
          type: 'html',
          name: 'project_results_html',
          html: '<div>{html_markup}</div>'
        },
        {
          type: 'html',
          name: 'project_flow_results_html',
          html: '<div>{html_flow_markup}</div>'
        },
        {
          type: 'boolean',
          name: 'send_survey',
          title: 'Would you still like to send your survey to the team?',
          isRequired: true,
          swapOrder: true
        }
      ],
      title: '{final_step_page_title}',
      description: '{final_step_page_description}'
    }
  ]
};
