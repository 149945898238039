import { combineReducers } from 'redux';

import {
  DatesByPage,
  FiltersByPage,
  ToggleObject,
  Topology,
  TopologyFieldNames
} from 'types';
import { CurrentLabState } from 'types/labs/lab';

import { chatSlice } from 'features/chatbot';
import { contactsSlice } from 'features/contacts';

import * as actions from '../actions/actionsTypes';

export function departmentReducer(state: string[] = [], action: any) {
  switch (action.type) {
    case actions.DEPARTMENTS:
      return action.payload;
    default:
      return state;
  }
}

export function topologyReducer(state: Topology[] = [], action: any) {
  switch (action.type) {
    case actions.TOPOLOGIES:
      return action.payload;
    default:
      return state;
  }
}

const defaultDates = {
  contact: {
    startDate: '',
    endDate: ''
  },
  topology: {
    startDate: '',
    endDate: ''
  }
};

const defaultFilters = {
  contactFilterText: '',
  topologyFilterText: ''
};

export function datesReducer(state: DatesByPage = defaultDates, action: any) {
  switch (action.type) {
    case actions.DATEINPUT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function filterReducer(
  state: FiltersByPage = defaultFilters,
  action: any
) {
  switch (action.type) {
    case actions.FILTERINPUT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function topActiveReducer(state: number = 5, action: any) {
  switch (action.type) {
    case actions.TOPACTIVE:
      return action.payload;
    default:
      return state;
  }
}

export function toggleUIReducer(
  state: ToggleObject = { default: true, value: 'false' },
  action: any
) {
  switch (action.type) {
    case actions.TOGGLEUI:
      return action.payload;
    default:
      return state;
  }
}

export const initialFieldName: TopologyFieldNames = {
  owner: '',
  template: '',
  templateId: [], // no template is selected by default
  region: '',
  expirationTime: '',
  templateExpiration: 0
};

export function topologyFieldNameReducer(
  state: TopologyFieldNames = initialFieldName,
  action: any
) {
  switch (action.type) {
    case actions.FIELDVALUES:
      return action.payload;
    default:
      return state;
  }
}

export const initialLabState: CurrentLabState = {
  lab: undefined,
  isContent: false
};

export function labReducer(
  state: CurrentLabState = initialLabState,
  action: any
) {
  switch (action.type) {
    case actions.LAB_LOADED:
      return action.payload;
    default:
      return state;
  }
}

const appReducers = combineReducers({
  contact: contactsSlice.reducer,
  department: departmentReducer,
  topology: topologyReducer,
  dates: datesReducer,
  filters: filterReducer,
  active: topActiveReducer,
  toggleValue: toggleUIReducer,
  fieldValues: topologyFieldNameReducer,
  chat: chatSlice.reducer,
  labState: labReducer
});

export type RootState = ReturnType<typeof appReducers>;

export default appReducers;
