import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Segment, Tab } from 'semantic-ui-react';
import { authUtils, contactUtils, interpolateRoute } from 'utils';

import { CloudlabsBanner, QuickLinks, Topologies } from 'components';
import { Feature } from 'components/FeatureFlags';
import { Labs } from 'components/Labs';
import { routes } from 'constants/routing';

import { DashboardTableFilter } from './DashboardTableFilter';

import './styles.less';

type Props = {
  readonly id?: number;
  readonly name?: string;
};

export const DashboardRedesign: React.FC<Props> = memo(({ id, name }) => {
  const [pageLimit, setPageLimit] = useState('5');
  const [filter, setFilter] = useState('owned');
  const user = authUtils.getLoggedInUser();
  const LabTable = () => (
    <div data-testid='lab'>
      <Labs
        displayedColumns={[
          'lab name',
          'region',
          'creation time',
          'created by',
          'created for',
          'status',
          'progress',
          'topology',
          'actions'
        ]}
        limit={Number(pageLimit)}
        createdById={['both', 'managed'].includes(filter) ? [user.id] : []}
        createdForId={['both', 'owned'].includes(filter) ? [user.id] : []}
      />
      <div className='view-topologies-button'>
        <Link
          to={interpolateRoute(routes.labs.path, {
            id
          })}
        >
          <Button primary>View all labs</Button>
        </Link>
      </div>
    </div>
  );
  const TopologyTable = () => (
    <div data-testid='topology'>
      <Topologies
        owner={['both', 'managed'].includes(filter) ? [user.id] : []}
        limit={Number(pageLimit)}
        displayedColumns={[
          'topology name',
          'region',
          'creation time',
          'expiration time',
          'created by',
          'created for',
          'department',
          'tags',
          'status',
          'actions'
        ]}
        selectionType={'none'}
        createdForId={['both', 'owned'].includes(filter) ? [user.id] : []}
      />
      <div className='view-topologies-button'>
        <Link
          to={interpolateRoute(routes.topologies.path, {
            id
          })}
        >
          <Button primary>View all topologies</Button>
        </Link>
      </div>
    </div>
  );
  const panes = [
    {
      menuItem: contactUtils.isAdminOrSE(user.role)
        ? {
            key: 'lab',
            id: 'lab',
            content: 'Labs'
          }
        : null,
      render: () => (
        <Feature name={user.role}>
          <Tab.Pane>
            <DashboardTableFilter
              pageLimit={pageLimit}
              filter={filter}
              onPageLimitChange={setPageLimit}
              onFilterChange={setFilter}
            />
            <LabTable />
          </Tab.Pane>
        </Feature>
      )
    },
    {
      menuItem: 'Topologies',
      render: () => (
        <Tab.Pane>
          <DashboardTableFilter
            pageLimit={pageLimit}
            filter={filter}
            onPageLimitChange={setPageLimit}
            onFilterChange={setFilter}
          />
          <TopologyTable />
        </Tab.Pane>
      )
    }
  ];
  return (
    <div className='dashboard-redesign'>
      <Grid.Row className='welcome-banner' width={11}>
        <Segment basic>
          <h1>Hello {name}</h1>
          <h3>Welcome back to Juniper Apstra Cloudlabs</h3>
          <CloudlabsBanner />
        </Segment>
      </Grid.Row>
      <QuickLinks id={id} />
      <h2 className='juniper-color table-header-text'>My Resources</h2>
      <Tab
        menu={{
          secondary: true,
          pointing: true,
          fluid: true
        }}
        defaultActiveIndex={Number(!contactUtils.isAdminOrSE(user.role))}
        data-testid='dashboard-tabs'
        className='tab-align'
        panes={panes}
      />
    </div>
  );
});
