import React, { memo, SyntheticEvent, useEffect, useState } from 'react';
import {
  Button,
  Dimmer,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Header,
  Loader,
  Message,
  Popup,
  Progress
} from 'semantic-ui-react';

import { milestonesApi } from '../../../api/milestone';
import { message as apiErrorMessage } from '../../../components';
import { MilestoneStatus } from '../../../interfaces/response/milestone-status';
import { Milestone } from '../../../types';

type Props = {
  readonly topologyUuid: string;
  readonly isUp: boolean;
  readonly isInactive: boolean;
};

enum MilestoneStates {
  InProgress = 'in progress',
  NotStarted = 'not started',
  Failed = 'fail',
  Succeeded = 'success'
}

class MilestoneState {
  state: MilestoneStates;
  message: string | null;
  name: string | null;
  progress: number | null = null;

  constructor(
    state: MilestoneStates,
    message: string | null,
    name: string | null = null,
    progress: number = 0
  ) {
    this.message = message;
    this.state = state;
    this.name = name;
    this.progress = progress;
  }

  toString(): string {
    return `<MilestoneState(state=${this.state},message=${this.message},name=${this.name}>`;
  }

  statusMessage(): string {
    if (this.state === MilestoneStates.Failed) {
      return this.message || `Failed to apply milestone ${this.name}`;
    } else if (this.state === MilestoneStates.Succeeded) {
      return 'Milestone successfully applied';
    } else if (this.state === MilestoneStates.InProgress) {
      return this.message || 'Milestone in progress';
    } else {
      return 'Milestone not started';
    }
  }

  static inProgress(message: string | null = null): MilestoneState {
    return new MilestoneState(MilestoneStates.InProgress, message);
  }

  static failure(message: string): MilestoneState {
    return new MilestoneState(MilestoneStates.Failed, message);
  }

  static notStarted(): MilestoneState {
    return new MilestoneState(MilestoneStates.NotStarted, null);
  }

  static fromBackendApiResponse(resp: MilestoneStatus) {
    const state: MilestoneStates =
      resp.state === null
        ? MilestoneStates.NotStarted
        : (resp.state as MilestoneStates);
    return new MilestoneState(
      state,
      resp.statusMessage,
      resp.milestoneName,
      resp.progress
    );
  }
}

function milestonesToOptions(milestones: any[]): DropdownItemProps[] {
  return milestones.map(milestone => {
    return {
      key: milestone.name,
      value: milestone.name,
      text: milestone.label,
      content: (
        <Header
          as='h4'
          content={milestone.label}
          subheader={milestone.description}
        />
      )
    };
  });
}

export const MilestoneCard: React.FC<Props> = memo(
  ({ topologyUuid, isUp, isInactive }) => {
    const applyMilestone = async () => {
      setMessageVisible(true);
      if (!selectedMilestone) {
        setMilestoneState(MilestoneState.failure('Please select milestone'));
        return;
      }
      try {
        await milestonesApi.apply(topologyUuid, selectedMilestone);
        setMilestoneState(MilestoneState.inProgress());
      } catch (err) {
        setMilestoneState(
          MilestoneState.failure(
            `Failed to apply milestone: ${apiErrorMessage(err.response)}`
          )
        );
      }
    };

    const [milestones, setMilestones] = useState<Milestone[]>([]);
    const [selectedMilestone, setSelectedMilestone] = useState<string>();
    const [milestoneState, setMilestoneState] = useState<MilestoneState>(
      MilestoneState.notStarted()
    );
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [messageVisible, setMessageVisible] = useState<boolean>(true);

    const handleChange = (
      e: SyntheticEvent<HTMLElement, Event>,
      { value }: DropdownProps
    ) => setSelectedMilestone(value as string);

    useEffect(() => {
      const getMilestoneStatus = async () => {
        if (milestones.length === 0) {
          return;
        }
        try {
          const res = await milestonesApi.getStatus(topologyUuid);
          setMilestoneState(MilestoneState.fromBackendApiResponse(res));
        } catch (err) {
          setMilestoneState(
            MilestoneState.failure(
              `Failed to get milestone state: ${apiErrorMessage(err.response)}`
            )
          );
        }
      };

      const id = setInterval(() => {
        getMilestoneStatus();
      }, 5000);

      return () => clearInterval(id);
    }, [topologyUuid, milestones]);

    useEffect(() => {
      const getMilestones = async () => {
        try {
          setIsFetching(true);
          const res = await milestonesApi.getAll(topologyUuid);
          setMilestones(res.milestones);
          if (res.milestones.length > 0) {
            setSelectedMilestone(res.milestones[0].name);
          }
        } catch (err) {
          setMilestoneState(
            MilestoneState.failure(
              `Failed to get milestones for topology: ${apiErrorMessage(
                err.response
              )}`
            )
          );
        } finally {
          setIsFetching(false);
        }
      };
      if (isInactive) {
        setIsFetching(false);
      } else {
        getMilestones();
      }
    }, [topologyUuid, isInactive]);

    if (!isInactive && !isUp && isFetching) {
      return (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      );
    }
    if (milestones.length === 0 || !isUp) {
      return (
        <Message
          negative={milestoneState.state === MilestoneStates.Failed}
          positive={milestoneState.state === MilestoneStates.Succeeded}
          info={
            milestoneState.state in
            [MilestoneStates.InProgress, MilestoneStates.NotStarted]
          }
        >
          {milestoneState.statusMessage()}
        </Message>
      );
    }
    return (
      <div>
        <Dropdown
          placeholder='Select milestone'
          fluid
          selection
          compact
          value={selectedMilestone}
          options={milestonesToOptions(milestones)}
          onChange={handleChange}
          loading={isFetching}
        />
        <br />
        <Popup
          trigger={
            <Button
              primary
              className='open-btn'
              size='medium'
              loading={milestoneState.state === MilestoneStates.InProgress}
              disabled={
                isInactive ||
                milestoneState.state === MilestoneStates.InProgress
              }
              onClick={applyMilestone}
            >
              {'Apply milestone'}
            </Button>
          }
          content={
            <div>
              Milestone 2, Milestone 3 and Milestone 4 will delete ALL
              blueprints in the users Apstra instance.
              <br />A new blueprint will be created with the name “apstra-pod1”
              and configured to the given milestone.
            </div>
          }
          position='bottom center'
          flowing
          style={{ background: '#fffaf3', color: '#573a08' }}
          basic
          hoverable
        />

        {messageVisible && (
          <Message
            onDismiss={() => setMessageVisible(false)}
            negative={milestoneState.state === MilestoneStates.Failed}
            positive={milestoneState.state === MilestoneStates.Succeeded}
            info={
              milestoneState.state in
              [MilestoneStates.InProgress, MilestoneStates.NotStarted]
            }
          >
            {milestoneState.statusMessage()}
            {milestoneState.state === MilestoneStates.InProgress && (
              <Progress percent={milestoneState.progress || 0} size={'tiny'}>
                {milestoneState.progress}%
              </Progress>
            )}
          </Message>
        )}
      </div>
    );
  }
);
