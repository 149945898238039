import React, { Fragment, memo, useState } from 'react';
import { Confirm } from 'semantic-ui-react';

import { notifier, paginationUtils } from 'utils';
import { ungroupByTopologies } from 'utils/labs';

import { LabsTable } from 'components/LabsTable';
import { getActiveItems } from 'components/TopologyReports/UsageTable/ReportFilterHelpers';
import { useFilterGetAllLabs, useLabMutations } from 'hooks/useLabTemplate';
import { Lab, PaginationFilters } from 'types';

type Props = {
  readonly displayedColumns: string[];
  readonly limit: number;
  readonly offset?: number;
  readonly createdForId?: number[];
  readonly createdById?: number[];
};

const filterItems = (
  labs: Lab[],
  pagination: PaginationFilters
): {
  total: number;
  items: Lab[];
} => {
  return {
    total: labs.length,
    items: getActiveItems(pagination, labs)
  };
};

export const Labs: React.FC<Props> = memo(
  ({ displayedColumns, createdForId, createdById, limit }) => {
    const [pagination, setPagination] = useState<PaginationFilters>({
      activePage: 1,
      pageSize: limit
    });
    const [confirmDeleteModelOpen, setConfirmDeleteModelOpen] = useState(false);
    const [selectedlab, setSelectedLab] = useState<Lab | null>(null);
    const { useDeleteLab } = useLabMutations();
    const { response, isLoading } = useFilterGetAllLabs(
      createdForId,
      createdById
    );
    const labList = ungroupByTopologies(response || []);
    const { total, items } = filterItems([...labList], pagination);
    const totalPages = paginationUtils.getTotalPages({
      pageSize: pagination.pageSize,
      totalCount: total
    });
    const onDelete = async () => {
      if (!selectedlab) {
        return;
      }
      try {
        useDeleteLab.mutate(selectedlab.uuid);
      } catch (err) {
        notifier.requestFailed(err);
      }
      setConfirmDeleteModelOpen(false);
    };
    return (
      <Fragment>
        <LabsTable
          labs={items}
          displayedColumns={displayedColumns}
          isLoading={isLoading}
          onDelete={lab => {
            setSelectedLab(lab);
            setConfirmDeleteModelOpen(true);
          }}
          setPagination={setPagination}
          pagination={pagination}
          activePage={pagination.activePage}
          totalPages={totalPages}
        />
        <Confirm
          size='mini'
          content={`Are you sure you want to delete ${
            selectedlab ? selectedlab.label : 'this lab'
          }?`}
          open={confirmDeleteModelOpen}
          onCancel={() => setConfirmDeleteModelOpen(false)}
          onConfirm={() => onDelete()}
          confirmButton='Delete'
        />
      </Fragment>
    );
  }
);
