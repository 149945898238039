import axios from 'axios';
import { isEmpty } from 'lodash';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';

import { notifier } from 'utils';

import { apiPrefix } from 'constants/api-routes';
import { Lab, LabRequest, LabTemplate, Progress } from 'types';

import qs from 'qs';

export const useLabTemplates = (): UseQueryResult<LabTemplate[]> => {
  return useQuery<LabTemplate[]>('LabTemplates', () => {
    return axios
      .get(apiPrefix('/v1.3/labtemplates'))
      .then(response => (response.data ? response.data.labTemplates : []))
      .catch(error => {
        notifier.requestFailed(error);
        return [];
      });
  });
};

export const useFilterGetAllLabs = (
  createdForId: number[] = [],
  createdById: number[] = []
) => {
  const { data, isLoading } = useGetAllLabs();
  let response = data || [];
  if (!isEmpty(createdForId) && !isEmpty(createdById)) {
    response = response.filter(
      (lab: Lab) =>
        createdById.includes(lab.createdBy.id) &&
        createdForId.includes(lab.createdFor.id)
    );
  } else if (!isEmpty(createdForId)) {
    response = response.filter((lab: Lab) =>
      createdForId.includes(lab.createdFor.id)
    );
  } else if (!isEmpty(createdById)) {
    response = response.filter((lab: Lab) =>
      createdById.includes(lab.createdBy.id)
    );
  }
  return { response, isLoading };
};

export const useGetAllLabs = (params?: any): UseQueryResult<Lab[]> =>
  useQuery<Lab[]>('Labs', async () => {
    return await axios
      .get(apiPrefix(`/v1.3/labs`), {
        params,
        paramsSerializer: param => {
          return qs.stringify(param, { arrayFormat: 'repeat' });
        }
      })
      .then(resp => (resp.data ? resp.data.labs : []))
      .catch(error => {
        notifier.requestFailed(error);
        return [];
      });
  });

export const useGetLab = (uuid: string): UseQueryResult<Lab> =>
  useQuery<Lab>('Lab', () => {
    return axios
      .get(apiPrefix(`/v1.3/labs/${uuid}`))
      .then(response => response.data)
      .catch(error => {
        notifier.requestFailed(error);
        return [];
      });
  });
export const useLabMutations = () => {
  const queryClient = useQueryClient();

  const useCreateLab = useMutation(
    (labRequest: LabRequest) => {
      return axios
        .post(apiPrefix('/v1.3/labs/deploy'), labRequest)
        .then(response => {
          notifier.success({
            message: `Lab Created.`
          });
          notifier.success({
            message: `Scheduled creation of Topology.`
          });
          return response.data;
        })
        .catch(error => {
          notifier.requestFailed(error);
        });
    },
    { onSettled: () => queryClient.invalidateQueries('Labs') }
  );

  const useDeleteLab = useMutation(
    (uuid: string) => {
      return axios
        .delete(apiPrefix(`/v1.3/labs/${uuid}`))
        .then(response => {
          notifier.success({
            message: `Lab deleted.`
          });
        })
        .catch(error => {
          notifier.requestFailed(error);
        });
    },
    { onSuccess: () => queryClient.invalidateQueries('Labs') }
  );

  const useUpdateLab = useMutation(
    (data: { uuid: string; progress: Progress }) => {
      return axios
        .put(apiPrefix(`/v1.3/lab/${data.uuid}/progress`), data.progress)
        .then(response => {
          notifier.success(
            {
              message: 'Saved progress of lab'
            },
            { autoClose: 2000 }
          );
        })
        .catch(error => {
          notifier.requestFailed(error);
        });
    },
    { onSuccess: () => queryClient.invalidateQueries('Lab') }
  );

  return {
    useCreateLab,
    useDeleteLab,
    useUpdateLab
  };
};
