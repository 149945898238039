import { isEmpty } from 'lodash';

import { Lab, LabGuideUrl } from 'types';

import { isNullOrUndefined } from './utilFunctions';

export const ungroupByTopologies = (labs: Lab[]): Lab[] => {
  if (isEmpty(labs)) {
    return [];
  }
  const newLab: Lab[] = [];
  labs.forEach((lab: Lab) => {
    if (lab.topologies.length) {
      const topologies = lab.topologies;
      for (const topology of topologies) {
        const tempLab: Lab = { ...lab, topologies: [topology] };
        newLab.push(tempLab);
      }
    } else {
      newLab.push(lab);
    }
  });
  return newLab;
};

export const getLabTemplateUrls = (lab: Lab): LabGuideUrl[] => {
  if (isNullOrUndefined(lab)) {
    return [];
  }
  return lab.labTemplate?.labGuideUrls;
};

export const calculateLabProgress = (lab: Lab) => {
  return lab.progress && lab.progress.currentPage > 0
    ? ((lab.progress.currentPage / lab.progress.totalPages) * 100).toFixed(0)
    : 0;
};
