import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { notifier } from 'utils';

import { apiPrefix } from 'constants/api-routes';
import { ContactListResponse } from 'interfaces';
import { Contact } from 'types';

import qs from 'qs';
import { RootState } from 'Reducers/contactReducer';

function undefinedIfNull(s: string | null): string | undefined {
  if (s === null || s === '') {
    return undefined;
  } else {
    return s;
  }
}

function apiRoleFromUIRole(uiRole: string | null): string[] | undefined {
  if (uiRole === 'all' || uiRole === null) {
    return undefined;
  } else if (uiRole === 'customer') {
    return ['external'];
  } else if (uiRole === 'partner') {
    return ['partner', 'partner_admin'];
  } else {
    return [uiRole];
  }
}

async function getContacts(
  page: number,
  pageSize: number,
  filter: string | null,
  roleFilter: string | null
) {
  const res = await axios.get(apiPrefix('/v1.0/contacts'), {
    params: {
      offset: page - 1,
      limit: pageSize,
      filter: undefinedIfNull(filter),
      role: apiRoleFromUIRole(roleFilter)
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
  });
  return res.data;
}

export const fetchContacts = createAsyncThunk(
  'contacts/fetchContacts',
  async (
    props: {
      page?: number;
      limit?: number;
      filter?: string | null;
      role?: string | null;
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const page =
      props.page === undefined ? state.contact.currentPage : props.page;
    const limit = props.limit === undefined ? state.contact.limit : props.limit;
    const filter =
      props.filter === undefined ? state.contact.filter.text : props.filter;
    const role =
      props.role === undefined ? state.contact.filter.role : props.role;

    return getContacts(page, limit, filter, role).catch(err => {
      notifier.requestFailed(err);
      return thunkAPI.rejectWithValue(err.response.data);
    });
  }
);

function setStateFromSuccessfulResponse(
  state: ContactsState,
  action: PayloadAction<ContactListResponse>
) {
  state.requestState = 'succeeded';
  state.contacts = action.payload.contacts;
  state.totalPages = Math.ceil(action.payload.total / state.limit);
  state.currentPage = action.payload.offset + 1;
  state.totalContacts = action.payload.total;
  state.error = '';
}

type RequestState = 'idle' | 'failed' | 'succeeded' | 'loading' | 'update';
export type ContactsFilters = {
  readonly text: string;
  readonly role: string | null;
};
export interface ContactsState {
  contacts: Contact[];
  currentPage: number;
  error: string;
  filter: ContactsFilters;
  limit: number;
  requestState: RequestState;
  selected: Contact[];
  totalContacts: number;
  totalPages: number;
}
const initialState: ContactsState = {
  contacts: [],
  currentPage: 1,
  error: '',
  filter: { text: '', role: null },
  limit: 10,
  requestState: 'idle',
  selected: [],
  totalContacts: 0,
  totalPages: 1
};
export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setFilter: (
      state: ContactsState,
      action: PayloadAction<ContactsFilters>
    ) => {
      state.filter = action.payload;
    },
    setCurrentPage: (state: ContactsState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSelectedContacts: (
      state: ContactsState,
      action: PayloadAction<Contact[]>
    ) => {
      state.selected = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchContacts.pending, state => {
        state.requestState = 'loading';
      })
      .addCase(fetchContacts.rejected, (state, action) => {
        state.requestState = 'failed';
        state.error = (action.payload as string) || 'Unknown error';
      })
      .addCase(fetchContacts.fulfilled, setStateFromSuccessfulResponse);
  }
});

export const {
  setSelectedContacts,
  setFilter,
  setCurrentPage
} = contactsSlice.actions;

export default contactsSlice.reducer;
