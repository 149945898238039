import React, { memo } from 'react';

import { Labs } from 'components/Labs';
import { DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { MainLayout } from 'layouts';

export const LabsView: React.FC = memo(() => {
  return (
    <MainLayout className='cl-topologies-view'>
      <div className='caption'>
        <h2 className='header'>Labs</h2>
      </div>
      <Labs
        displayedColumns={[
          'lab name',
          'region',
          'creation time',
          'created by',
          'created for',
          'progress',
          'topology'
        ]}
        limit={DEFAULT_PAGE_SIZE}
      />
    </MainLayout>
  );
});
