import React, { memo } from 'react';
import { Icon, Popup, Table } from 'semantic-ui-react';

import {
  formatUTCDate,
  getUTCFromNow,
  interpolateRoute,
  notifier
} from 'utils';
import { calculateLabProgress } from 'utils/labs';

import { StateLabel } from 'components/StateLabel';
import { TextLink } from 'components/TextLink';
import { routes } from 'constants/routing';
import { Lab, Topology } from 'types';

type Props = {
  readonly lab: Lab;
  readonly displayedColumns: string[];
  readonly onDelete: (lab: Lab) => void;
  readonly topology?: Topology;
};

export const LabRow: React.FC<Props> = memo(
  ({ lab, displayedColumns, topology, onDelete }) => {
    return (
      <Table.Row key={topology ? topology.uuid : lab.uuid}>
        {displayedColumns.map(col => {
          switch (col) {
            case 'lab name':
              return (
                <Table.Cell key={col}>
                  <div className='topology-name-cell-content'>
                    <div>
                      <TextLink
                        to={interpolateRoute(routes.lab.path, {
                          uuid: lab.uuid
                        })}
                      >
                        {lab.label}
                      </TextLink>
                    </div>
                  </div>
                </Table.Cell>
              );
            case 'region':
              return <Table.Cell key={col}>{lab.region.label}</Table.Cell>;
            case 'creation time':
              return (
                <Table.Cell key={col}>
                  <div>{formatUTCDate(lab.createdAt)}</div>
                  <div className='text-small'>
                    {getUTCFromNow(lab.createdAt)}
                  </div>
                </Table.Cell>
              );
            case 'created by':
              return <Table.Cell key={col}>{lab.createdBy.name}</Table.Cell>;
            case 'created for':
              return <Table.Cell key={col}>{lab.createdFor.name}</Table.Cell>;
            case 'status':
              return (
                <Table.Cell className='status-cell' key={col}>
                  {topology ? (
                    <StateLabel state={topology.state} />
                  ) : (
                    <span>-</span>
                  )}
                </Table.Cell>
              );
            case 'progress':
              return (
                <Table.Cell textAlign='center' key={col}>
                  {calculateLabProgress(lab)}%
                </Table.Cell>
              );
            case 'topology':
              return (
                <Table.Cell key={col}>
                  <div className='topology-name-cell-content'>
                    {topology ? (
                      <div>
                        <TextLink
                          to={interpolateRoute(routes.topology.path, {
                            id: topology.uuid
                          })}
                        >
                          {topology.name}
                        </TextLink>
                      </div>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </Table.Cell>
              );
            case 'actions':
              return (
                <Table.Cell
                  collapsing
                  textAlign='center'
                  className='actions-cell'
                  key={col}
                >
                  <Popup
                    content='Delete'
                    position='top center'
                    trigger={
                      <Icon
                        link
                        name='trash alternate'
                        onClick={() => onDelete(lab)}
                      />
                    }
                  />
                </Table.Cell>
              );
            default:
              notifier.error({ message: `Unknown column: ${col}` });
          }
          return <></>;
        })}
      </Table.Row>
    );
  }
);
