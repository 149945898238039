import { isEmpty } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';

import { host, interpolateRoute } from 'utils';
import { getLabTemplateUrls } from 'utils/labs';
import { isNullOrUndefined } from 'utils/utilFunctions';

import { useLabMutations } from 'hooks/useLabTemplate';

import { routes } from 'constants/routing';
import { Lab } from 'types';

import { setLabDetails } from 'actions/actions';
import { initialLabState, RootState } from 'Reducers/contactReducer';
import { LabGuidePageNavigator } from '../LabGuidePageNavigator';
import { LabCompletionActions } from './LabCompletionActions';
import { LabDetailsOverlay } from './LabDetailsOverlay/LabDetailsOverlay';

import './styles.less';

type Props = {
  readonly lab: Lab;
  readonly visible: boolean;
  readonly onVisibleChange: (visible: boolean) => void;
  readonly onContentChange: (content: boolean) => void;
};

export const setIFrameAnchorsTarget = (document: Document) => {
  const anchors = document.querySelectorAll('a');
  if (anchors.length > 0) {
    for (const i in anchors) {
      if (anchors[i] && anchors[i].hostname !== host) {
        anchors[i].setAttribute('target', '_blank');
      }
    }
  }
};

export const LabGuideFrame: React.FC<Props> = memo(
  ({ lab, visible, onVisibleChange, onContentChange }) => {
    const [height, setHeight] = useState<string>('1100px');
    const dispatch = useDispatch();
    const labState: Lab = useSelector((state: RootState) => state.labState.lab);
    const isContent = useSelector(
      (state: RootState) => state.labState.isContent
    );
    const [activeSteps, setActiveSteps] = useState<number>(
      !isNullOrUndefined(lab.progress) ? lab.progress.currentPage : 0
    );
    const labGuideSrcLinks = getLabTemplateUrls(lab);
    const topologyUP =
      lab.topologies.length > 0 &&
      lab.topologies.every(topology => topology.state === 'up');
    const { useUpdateLab, useDeleteLab } = useLabMutations();
    const isFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
      input != null && input.tagName === 'IFRAME';
    const onLoad = () => {
      const frame = document.getElementById('iframe');
      if (isFrame(frame) && frame && frame.contentWindow) {
        const document = frame.contentWindow.document;
        if (document && document.body) {
          setHeight(`${document.body.clientHeight}px`);
          setIFrameAnchorsTarget(document);
        }
      }
    };
    const onDelete = () => {
      useDeleteLab.mutate(lab.uuid);
      dispatch(setLabDetails(initialLabState));
      window.location.href = interpolateRoute(routes.dashboard.path);
    };
    const updateProgress = (step: number) => {
      setActiveSteps(step);
      const data = {
        uuid: lab.uuid,
        progress: { currentPage: step, totalPages: lab.progress.totalPages }
      };
      useUpdateLab.mutate(data);
      dispatch(
        setLabDetails({
          lab: { ...labState, progress: data.progress },
          isContent
        })
      );
      const contentElement = document.getElementById('content');
      if (contentElement) {
        contentElement.scrollTo(0, 0);
      }
    };
    useEffect(() => {
      onLoad();
      // eslint-disable-next-line
    }, []);
    useEffect(() => {
      if (labState && labState.progress) {
        setActiveSteps(labState.progress.currentPage);
      }
    }, [labState]);
    const url =
      !isEmpty(labGuideSrcLinks) &&
      !isNullOrUndefined(labGuideSrcLinks[activeSteps])
        ? `${host}/lab${labGuideSrcLinks[activeSteps].url}`
        : '';
    return (
      <div>
        <LabDetailsOverlay topologies={lab.topologies} visible={!topologyUP} />
        {activeSteps !== labGuideSrcLinks.length && (
          <div className='sticky-div'>
            {!visible && (
              <Button
                className='align-right'
                data-testid='lab-details-button'
                disabled={!topologyUP}
                onClick={() => {
                  onVisibleChange(!visible);
                  onContentChange(false);
                  dispatch(
                    setLabDetails({
                      lab: labState,
                      isContent: false
                    })
                  );
                }}
              >
                <Icon name='info circle' />
                Lab Details
              </Button>
            )}
            {!visible && (
              <Button
                className='align-right'
                data-testid='lab-contents-button'
                disabled={!topologyUP}
                onClick={() => {
                  onVisibleChange(!visible);
                  onContentChange(true);
                  dispatch(
                    setLabDetails({
                      lab: labState,
                      isContent: true
                    })
                  );
                }}
              >
                <Icon name='list' />
                Contents
              </Button>
            )}
          </div>
        )}

        <div className='lab-frame'>
          {!isEmpty(url) && activeSteps !== labGuideSrcLinks.length && (
            <iframe
              id='iframe'
              title='LabGuideFrame'
              onLoad={onLoad}
              data-testid='lab-guide-frame'
              scrolling='no'
              className='container'
              src={url}
              referrerPolicy='origin'
              height={height}
            />
          )}
        </div>
        <div className='lab-frame'>
          {!isEmpty(url) && activeSteps !== labGuideSrcLinks.length && (
            <LabGuidePageNavigator
              activeSteps={activeSteps}
              stepItemsLength={labGuideSrcLinks.length}
              disablePrevButton={activeSteps === 0}
              disableNextButton={
                activeSteps === labGuideSrcLinks.length || !topologyUP
              }
              setActiveSteps={step => updateProgress(step)}
            />
          )}
        </div>
        {activeSteps === labGuideSrcLinks.length && (
          <LabCompletionActions
            lab={lab}
            onDelete={onDelete}
            onRestart={updateProgress}
          />
        )}
      </div>
    );
  }
);
