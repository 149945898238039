import { map } from 'lodash';
import React, { memo } from 'react';
import { Dimmer, Loader, Table } from 'semantic-ui-react';

import { CustomTableHeaderRow } from 'components/CustomTableHeaderRow';
import { Pagination } from 'components/TablePagination';
import { Lab, PaginationFilters } from 'types';

import { LabRow } from './LabRow';

type Props = {
  readonly labs: Lab[];
  readonly displayedColumns: string[];
  readonly isLoading: boolean;
  readonly onDelete: (lab: Lab) => void;
  readonly activePage: number;
  readonly totalPages: number;
  readonly pagination: PaginationFilters;
  readonly setPagination: React.Dispatch<
    React.SetStateAction<PaginationFilters>
  >;
};

export const LabsTable: React.FC<Props> = memo(
  ({
    labs,
    displayedColumns,
    isLoading,
    onDelete,
    activePage,
    totalPages,
    pagination,
    setPagination
  }) => {
    const header = (
      <Table.Header>
        <CustomTableHeaderRow
          selectionType={'none'}
          indeterminate={false}
          onChange={() => {
            return;
          }}
          displayedColumns={displayedColumns}
        />
      </Table.Header>
    );
    const footer = (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan='12'>
            <Pagination
              activePage={activePage}
              totalPages={totalPages}
              onPaginationChange={page =>
                setPagination({
                  ...pagination,
                  activePage: page as number
                })
              }
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
    if (isLoading) {
      return (
        <Dimmer.Dimmable as={Table} dimmed>
          {header}
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Dimmer.Inner active inverted>
                  <Loader active>Loading...</Loader>
                </Dimmer.Inner>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
          {footer}
        </Dimmer.Dimmable>
      );
    }
    return (
      <Table celled striped unstackable className='cl-topologies-table'>
        {header}
        <Table.Body>
          {map(labs, lab => {
            if (lab.topologies.length) {
              return map(lab.topologies, topology => {
                return (
                  <LabRow
                    key={topology.uuid}
                    lab={lab}
                    displayedColumns={displayedColumns}
                    onDelete={onDelete}
                    topology={topology}
                  />
                );
              });
            } else {
              return (
                <LabRow
                  key={lab.uuid}
                  lab={lab}
                  displayedColumns={displayedColumns}
                  onDelete={onDelete}
                />
              );
            }
          })}
        </Table.Body>
        {footer}
      </Table>
    );
  }
);
