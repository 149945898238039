import React, { createRef, useCallback, useState } from 'react';
import { Button, TextArea } from 'semantic-ui-react';

import './EditableInput.less';

interface EditableInputProps {
  /**
   * The initial text to display in the component.
   */
  text: string;

  /**
   * Indicates whether the input should start in editing mode.
   * Defaults to `false`.
   */
  editing?: boolean;

  /**
   * Indicates whether the input should be displayed with an error style.
   * Defaults to `false`.
   */
  error?: boolean;

  /**
   * Callback function to be called when changes are accepted.
   * Receives the new value as an argument.
   */
  onAcceptChanges?: (newValue: string) => void;

  /**
   * Callback function to be called when changes are canceled.
   */
  onCancelChanges?: () => void;
}

/**
 * EditableInput component allows inline editing of text.
 *
 * This component displays a text by default. When the user clicks on the text,
 * it switches to an editable state where the text can be modified within a textarea.
 * The changes can be accepted by pressing Enter or by clicking the accept button,
 * and canceled by pressing Escape or by clicking the cancel button.
 *
 * Props:
 * - `text`: The initial text to display in the component.
 * - `editing` (optional): Indicates whether the input should start in editing mode.
 *   Defaults to `false`.
 * - `error` (optional): Indicates whether the input should be displayed with an error style.
 *   Defaults to `false`.
 * - `onAcceptChanges` (optional): Callback function to be called when changes are accepted.
 *   Receives the new value as an argument.
 * - `onCancelChanges` (optional): Callback function to be called when changes are canceled.
 *
 * Example usage:
 * ```tsx
 * <EditableInput
 *   text="Initial text"
 *   onAcceptChanges={(newValue) => console.log('Accepted changes:', newValue)}
 *   onCancelChanges={() => console.log('Canceled changes')}
 * />
 * ```
 */
const EditableInput: React.FC<EditableInputProps> = ({
  text,
  error,
  editing,
  onAcceptChanges,
  onCancelChanges
}) => {
  const [isEditing, setIsEditing] = useState(editing ?? false);
  const [value, setValue] = useState(text);
  const divRef = createRef<HTMLDivElement>();
  const [height, setHeight] = useState<number>();

  const handleEdit = () => {
    if (divRef.current) {
      setHeight(divRef.current.getBoundingClientRect().height);
    }
    setIsEditing(true);
  };

  const handleAcceptChanges = () => {
    if (onAcceptChanges) onAcceptChanges(value);
    setIsEditing(false);
  };

  const handleCancelChanges = useCallback(() => {
    setIsEditing(false);
    setValue(text);
    if (onCancelChanges) onCancelChanges();
  }, [onCancelChanges, text]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') handleAcceptChanges();
    else if (e.key === 'Escape') handleCancelChanges();
  };

  return (
    <div
      ref={divRef}
      onClick={handleEdit}
      className={
        error || isEditing ? 'editable-input-editing' : 'editable-input'
      }
      style={{
        height: error || isEditing ? height : 'auto'
      }}
    >
      {error || isEditing ? (
        <div>
          <TextArea
            value={value}
            onChange={e => setValue(e.target.value)}
            onKeyDown={handleKeyDown}
            className={error ? 'error' : ''}
            style={{ height }}
            autoFocus
          />
          <div className={'editable-input-buttons'}>
            <Button
              icon='checkmark'
              onClick={e => {
                e.stopPropagation();
                handleAcceptChanges();
              }}
              aria-label='Accept changes'
            />
            <Button
              icon='close'
              onClick={e => {
                e.stopPropagation();
                handleCancelChanges();
              }}
              aria-label='Cancel changes'
            />
          </div>
        </div>
      ) : (
        value
      )}
    </div>
  );
};

export { EditableInput };
