import React, { memo } from 'react';
import { Icon, Popup, Table } from 'semantic-ui-react';
import {
  authUtils,
  contactUtils,
  formatUTCDate,
  getUTCFromNow,
  interpolateRoute,
  notifier,
  onSendEmail,
  tagUtils,
  TopologyStatus
} from 'utils';

import { ExtendTopologyDuration } from 'components/ExtendTopologyDuration';
import { Nbsp } from 'components/Nbsp/Nbsp';
import { StateLabel } from 'components/StateLabel';
import { TextLink } from 'components/TextLink';
import { routes } from 'constants/routing';
import { Template, Topology } from 'types';
import { Tag } from 'types/tag';
import { ContactName } from 'views/Topologies/ContactName';

import { TagCell } from './TagCell';

type Props = {
  readonly topology: Topology;
  readonly template: Template | undefined;
  readonly displayedColumns: string[];
  readonly onDelete: (topology: Topology) => void;
  readonly onExtendDuration: () => void;
};

function TopologyNameCellContent(props: {
  topology: Topology;
  template: Template | undefined;
}) {
  const nameContent = () => {
    if (props.topology.label) {
      return (
        <div>
          <TextLink
            to={interpolateRoute(routes.topology.path, {
              id: props.topology.uuid
            })}
          >
            <span>{props.topology.label}</span>
          </TextLink>
          <p />
          <span className='text-small'>
            Original name:
            <Nbsp />
            {props.topology.name}
          </span>
        </div>
      );
    } else {
      return (
        <TextLink
          to={interpolateRoute(routes.topology.path, {
            id: props.topology.uuid
          })}
        >
          <span>{props.topology.name}</span>
        </TextLink>
      );
    }
  };

  return (
    <div className='topology-name-cell-content'>
      <div>
        {nameContent()}
        {props.template && (
          <div className='text-small'>Template: {props.template.name}</div>
        )}
      </div>
      {props.topology.description && (
        <div>
          <Popup
            trigger={<Icon name='info circle' />}
            content={
              <div>
                <Icon name='info circle' inverted />
                <span>{props.topology.description}</span>
              </div>
            }
            position='top right'
            inverted
            flowing
            style={{ background: '#5a5a5a' }}
            basic
            hoverable
          />
        </div>
      )}
    </div>
  );
}

export const TopologyRow: React.FC<Props> = memo(
  ({ topology, displayedColumns, template, onDelete, onExtendDuration }) => {
    const departmentTag = (tags: Tag[]) => {
      const departments = tags.filter(tag => {
        return !tagUtils.nonDepartmentTag(tag);
      });
      return departments.length > 0 ? departments[0].value : '-';
    };
    return (
      <>
        {displayedColumns.map(col => {
          switch (col) {
            case 'topology name':
              return (
                <Table.Cell key={col}>
                  <TopologyNameCellContent
                    topology={topology}
                    template={template}
                  />
                </Table.Cell>
              );
            case 'region':
              return (
                <Table.Cell key={col} collapsing>
                  {topology.region}
                </Table.Cell>
              );
            case 'creation time':
              return (
                <Table.Cell key={col} collapsing>
                  <div>{formatUTCDate(topology.creationTime)}</div>
                  <div className='text-small'>
                    {getUTCFromNow(topology.creationTime)}
                  </div>
                </Table.Cell>
              );
            case 'expiration time':
              return (
                <Table.Cell key={col} collapsing>
                  <div>{formatUTCDate(topology.expirationTime)}</div>
                  <div className='text-small'>
                    {getUTCFromNow(topology.expirationTime)}
                  </div>
                </Table.Cell>
              );
            case 'created by':
              return (
                <Table.Cell key={col} collapsing>
                  <ContactName
                    topology={topology}
                    contactProperty={'createdBy'}
                  />
                </Table.Cell>
              );
            case 'created for':
              return (
                <Table.Cell key={col} collapsing>
                  <ContactName
                    topology={topology}
                    contactProperty={'createdFor'}
                  />
                </Table.Cell>
              );
            case 'department':
              return (
                <Table.Cell key={col} collapsing className='status-cell'>
                  {departmentTag(topology.tags)}
                </Table.Cell>
              );
            case 'tags':
              return (
                <Table.Cell key={col}>
                  <TagCell tags={topology.tags} />
                </Table.Cell>
              );
            case 'status':
              return (
                <Table.Cell key={col} collapsing className='status-cell'>
                  <StateLabel state={topology.state} />
                </Table.Cell>
              );
            case 'actions':
              return (
                <Table.Cell
                  collapsing
                  textAlign='center'
                  className='actions-cell'
                  key={col}
                >
                  {contactUtils.isAdminOrInternal(
                    authUtils.getLoggedInUser()
                  ) && (
                    <Popup
                      content='Send Topology Details to Email'
                      position='top right'
                      trigger={
                        <Icon
                          link
                          name='mail'
                          onClick={() => onSendEmail(topology)}
                        />
                      }
                    />
                  )}
                  <ExtendTopologyDuration
                    trigger={
                      <Popup
                        content='Extend Duration'
                        position='top center'
                        trigger={<Icon link name='stopwatch' />}
                      />
                    }
                    topology={topology}
                    onSuccess={onExtendDuration}
                    enabled={topology.state === TopologyStatus.UP}
                    item={undefined}
                    showHeader={true}
                    icon={null}
                    direction={'left'}
                    scrolling={true}
                  />
                  <Popup
                    content='Delete'
                    position='top center'
                    trigger={
                      <Icon
                        link
                        name='trash alternate'
                        role={'button'}
                        aria-label={'Delete'}
                        onClick={() => onDelete(topology)}
                      />
                    }
                  />
                </Table.Cell>
              );
            default:
              notifier.error({ message: `Unknown column: ${col}` });
          }
          return <></>;
        })}
      </>
    );
  }
);
