import { filter, isEmpty, union, without } from 'lodash';
import React, { Fragment, memo, useState } from 'react';
import { Button, Grid, Icon, SemanticICONS } from 'semantic-ui-react';

import { notifier, TopologyStatus } from 'utils';

import { topologiesApi } from 'api/topology';
import { BlueprintNameModal } from 'components';
import { assets } from 'constants/assets';
import { TopologyAlterationAction, TopologyAlterationState } from 'enums';
import { TopologyDetails } from 'types';

import { Action, PredefinedActionCard } from '../PredefinedActionCard';

type Props = {
  topology: TopologyDetails;
  refetchData: () => void;
};

type PredefinedAction = Action & {
  readonly type: TopologyAlterationAction;
  readonly hidden?: boolean;
};

export const PredefinedActionsCard: React.FC<Props> = memo(
  ({ topology, refetchData }) => {
    const [actionsInProgress, setActionsInProgress] = useState<
      TopologyAlterationAction[]
    >([]);
    const [blueprintNameModalOpen, setBlueprintNameModalOpen] = useState(false);

    const setActionInProgress = (
      action: TopologyAlterationAction,
      value: boolean
    ) => {
      if (value) {
        setActionsInProgress(union(actionsInProgress, [action]));
      } else {
        setActionsInProgress(without(actionsInProgress, action));
      }
    };

    const onToggle = async (
      action: PredefinedAction,
      value: boolean,
      params?: any,
      onSuccess?: () => void
    ) => {
      try {
        setActionInProgress(action.type, true);
        await topologiesApi.toggleTopologyAction(
          topology.uuid,
          action.type,
          value,
          { params }
        );
        notifier.success({
          message: `${action.name} ${value ? 'enabled' : 'disabled'}`
        });
        if (onSuccess) {
          onSuccess();
        }
        // TODO: make refetchData returning a promise
        refetchData();
      } catch (err) {
        notifier.requestFailed(err);
      } finally {
        setActionInProgress(action.type, false);
      }
    };

    const isActionInProgress = (action: TopologyAlterationAction) => {
      return actionsInProgress.includes(action);
    };

    const getActionEnabled = (actionType: TopologyAlterationAction) => {
      const alteration = topology.alterations[actionType];
      const state = alteration ? alteration.state : null;
      return state === TopologyAlterationState.Enable;
    };

    const getActionFields = (action: PredefinedAction): PredefinedAction => {
      return {
        ...action,
        value: getActionEnabled(action.type),
        disabled: topology.state !== 'up' || isEmpty(topology.vms),
        loading: isActionInProgress(action.type),
        onToggle: (value: boolean) => onToggle(action, value)
      };
    };

    const actions: PredefinedAction[] = [
      {
        type: TopologyAlterationAction.ConfigChange,
        icon: 'stop' as SemanticICONS,
        name: 'Insert a Configuration Change',
        description: `Insert a static route on Spine2. As a result, Spine2 will report  a configuration deviation.`
      },
      {
        type: TopologyAlterationAction.InterfaceShut,
        icon: 'close' as SemanticICONS,
        name: 'Shutdown Switch Interface',
        description: `Shutdown interface swp1 on Spine1. As a result, spine1 will report errors in Apstra.`
      }
    ];

    const routerConfigAction: PredefinedAction = {
      type: TopologyAlterationAction.RouterConfig,
      iconSrc: assets.images.routerIcon.src,
      name: 'External Router Configuration',
      description: `Build External Router configuration for BGP peering based on Apstra blueprint configuration`,
      value: getActionEnabled(TopologyAlterationAction.RouterConfig),
      toggleDisabled: true
    };

    const addRouterConfiguration = async (name: string) => {
      onToggle(routerConfigAction, true, { bp_name: name }, () =>
        setBlueprintNameModalOpen(false)
      );
    };

    return (
      <Fragment>
        {filter(actions, action => !action.hidden)
          .map(action => getActionFields(action))
          .map(action => (
            <Grid.Column
              key={action.name}
              computer='8'
              largeScreen='4'
              widescreen='4'
            >
              <PredefinedActionCard action={action} />
            </Grid.Column>
          ))}
        <Grid.Column computer='8' largeScreen='4' widescreen='4'>
          <PredefinedActionCard action={routerConfigAction}>
            <Button
              primary
              onClick={() => setBlueprintNameModalOpen(true)}
              disabled={topology.state !== TopologyStatus.UP}
            >
              <Icon className='button' name='triangle right' />
              {'Add Configuration'}
            </Button>
          </PredefinedActionCard>
          <BlueprintNameModal
            open={blueprintNameModalOpen}
            onClose={() => setBlueprintNameModalOpen(false)}
            onSubmit={addRouterConfiguration}
          />
        </Grid.Column>
      </Fragment>
    );
  }
);
