/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import React, { memo } from 'react';
import { renderToString } from 'react-dom/server';
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Message,
  Segment
} from 'semantic-ui-react';
import { Survey } from 'survey-react-ui';
import { storage } from 'utils';

import {
  Email,
  NumberOfEmails,
  ValidatedInput
} from 'components/ValidatedInput';
import { NotAllowedChars } from 'components/ValidatedInput/Validators/NotAllowedChars';
import { MainLayout } from 'layouts';
import { BlueprintTestCaseOutputType, FlowSizingOutputType } from 'types';

import { Model } from 'survey-core';
import 'survey-core/defaultV2.css';
import BlueprintCard from './BlueprintCard';
import { survey as surveyJson } from './survey';
import {
  getCompletedSurveyObj,
  matchFlowSizingSurvey,
  matchSurvey,
  surveyComplete
} from './surveyApi';

import './styles.less';

const PurposeHeader: React.FC = memo(() => (
  <Message info>
    <Header>Purpose</Header>
    <p>
      Allows to estimate the minimal number of VMs and resources required for an
      Apstra deployment, given the number of managed fabrics and their scale.
    </p>
    <Header>How to use</Header>
    <p>
      Answer the questions in the form. If your project includes multiple
      fabrics of different scales – click 'Add new fabric type' at the bottom of
      the page. Provide accurate data and carefully review your input before
      submitting the form.
    </p>
    <Header>Results</Header>
    <p>
      Your project scale dimensions will be sent to the Apstra Product team over
      email and you will be contacted once the team has estimated the required
      resources based on the provided input.
    </p>
  </Message>
));

export function SizingMatchesContent(
  surveyMatches: BlueprintTestCaseOutputType[]
) {
  return renderToString(
    <Grid as={Segment} container divided relaxed data-testid='result-html'>
      {surveyMatches.map((match, i) => {
        return <BlueprintCard key={i} output={match} />;
      })}
    </Grid>
  );
}

export function FlowSizingMatchesContent(surveyMatch: FlowSizingOutputType) {
  return renderToString(
    <Grid
      as={Segment}
      centered
      celled='internally'
      textAlign='center'
      data-testid='result-flow-html'
    >
      <b className='flow-sizing-container'>Apstra Flow Sizing</b>
      <Grid.Row columns='2'>
        <Grid.Column>Number of Managed Devices</Grid.Column>
        <Grid.Column>{surveyMatch.number_of_managed_devices}</Grid.Column>
      </Grid.Row>
      <Grid.Row columns='2'>
        <Grid.Column>Flow Size</Grid.Column>
        <Grid.Column>{surveyMatch.flow_size}</Grid.Column>
      </Grid.Row>
      <Grid.Row columns='2'>
        <Grid.Column>Server Size</Grid.Column>
        <Grid.Column>{surveyMatch.server_size}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export function NoMatchesFoundContent() {
  return renderToString(
    <Segment padded>
      <Grid container divided>
        <Grid.Row>
          <Grid.Column>
            <Message warning>
              <p>
                Please click Complete button below to send this request to the
                Apstra sizing team for further review
              </p>
            </Message>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}

export function HideFlowSizingContent() {
  return renderToString(<></>);
}

export const ApstraSizingCalculatorViewInner: React.FC = memo(() => {
  const surveyModel = new Model(surveyJson);
  const surveyDataStorageKey = 'apstraSizingSurveyData';
  const defaultTo = 'apstra-sizing@juniper.net';
  const toEmails = [defaultTo];
  const [ccEmails, setCcEmails] = React.useState<string[]>([]);
  const saveSurveyData = (survey: Model) => {
    const data = survey.data;
    data.pageNo = survey.currentPageNo;
    storage.set(surveyDataStorageKey, JSON.stringify(data));
  };
  surveyModel.onValueChanged.add(survey => {
    saveSurveyData(survey);
  });
  surveyModel.onCurrentPageChanged.add(survey => {
    if (survey.currentPageNo === survey.pageCount - 1) {
      surveyModel.data = JSON.parse(storage.get(surveyDataStorageKey));
      const parsedSurvey = getCompletedSurveyObj(surveyModel);
      const matches = matchSurvey(parsedSurvey);
      const flowMatches = matchFlowSizingSurvey(parsedSurvey);
      if (matches.length !== 0) {
        survey.setVariable(
          'final_step_page_title',
          'Here are the suggestions we found based on your choices'
        );
        survey.setVariable(
          'final_step_page_description',
          'The survey you have filled out has some matches with our ' +
            'existing blueprints. You can review the details of each match below.'
        );
        survey.setVariable('html_markup', SizingMatchesContent(matches));
        survey.setVariable(
          'html_flow_markup',
          FlowSizingMatchesContent(flowMatches)
        );
      } else {
        survey.setVariable(
          'final_step_page_title',
          'Your request requires manual review.'
        );
        survey.setVariable('final_step_page_description', '');
        survey.setVariable('html_markup', NoMatchesFoundContent());
        survey.setVariable('html_flow_markup', HideFlowSizingContent());
        survey.setValue('send_survey', true);
      }
    }
  });
  surveyModel.onCompleting.add(survey => saveSurveyData(survey));
  const sendSurveyToApstraProductTeam = (survey: Model, options: any) => {
    const onSuccess = () => {
      storage.remove(surveyDataStorageKey);
      options.showSaveSuccess();
    };
    const beforeStart = () => {
      options.showSaveInProgress();
    };

    const onError = (reason: Error) => {
      options.showSaveError(reason);
    };
    surveyModel.data = JSON.parse(storage.get(surveyDataStorageKey));
    if (survey.getValue('send_survey').toString() === 'true') {
      surveyComplete(
        surveyModel,
        toEmails,
        ccEmails,
        beforeStart,
        onSuccess,
        onError
      );
    } else {
      beforeStart();
      onSuccess();
    }
  };
  surveyModel.onComplete.add((survey, options) =>
    sendSurveyToApstraProductTeam(survey, options)
  );

  // Restore survey results
  const prevData: string = storage.get(surveyDataStorageKey);
  if (prevData) {
    const data = JSON.parse(prevData);
    surveyModel.data = data;
    if (data.pageNo) {
      surveyModel.currentPageNo = data.pageNo;
    }
  }

  return (
    <Container>
      <Grid>
        <Grid.Row>
          <Header as={'h1'}>Apstra Sizing Calculator</Header>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <PurposeHeader />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched>
          <Grid.Column>
            <ValidatedInput
              placeholder='someone@apstra.com'
              label='To'
              icon='at'
              defaultValue={defaultTo}
              disabled={true}
              validators={[
                new NumberOfEmails(1),
                new Email(),
                new NotAllowedChars(',;')
              ]}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched>
          <Grid.Column>
            <ValidatedInput
              placeholder='someone@apstra.com'
              label='CC'
              icon='at'
              onBlur={(value: string) => setCcEmails(value.split(' '))}
              validators={[
                new NumberOfEmails(0),
                new Email(),
                new NotAllowedChars(',;')
              ]}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Button
            onClick={() => {
              storage.remove(surveyDataStorageKey);
              surveyModel.clear(true, true);
            }}
          >
            <Icon name='repeat' />
            Restart from the beginning
          </Button>
        </Grid.Row>
        <Grid.Row>
          <Survey model={surveyModel} />
        </Grid.Row>
      </Grid>
    </Container>
  );
});

export const ApstraSizingCalculatorView: React.FC = memo(() => {
  return (
    <MainLayout>
      <ApstraSizingCalculatorViewInner />
    </MainLayout>
  );
});
