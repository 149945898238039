import React, { memo, useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button, Card, Icon, Popup } from 'semantic-ui-react';

import { notifier } from 'utils';

import { RouterLink } from 'components';

type Props = {
  readonly bastionFqdn: string;
};

export const BastionUsageMessage: React.FC<Props> = memo(({ bastionFqdn }) => {
  const onFQDHCopy = useCallback(() => {
    notifier.success({ message: 'FQDN Copied' }, { autoClose: 2000 });
  }, []);

  return (
    <Card>
      <Card.Content>
        <Card.Header>SSH access to VMs</Card.Header>
        <Card.Content>
          <p>
            All devices in this topology are accessible using the "Connect"
            button below.
          </p>
          <p>
            When connected, input the number of the device you want to connect
            to from the menu. Login credentials are listed in the table.
          </p>
        </Card.Content>
      </Card.Content>
      <Card.Content>
        <Button primary>
          <RouterLink to={bastionFqdn}>{'Connect'}</RouterLink>
        </Button>
      </Card.Content>
      <Card.Content>
        <Popup
          content='Copy FQDN to Clipboard'
          position='top center'
          trigger={
            <CopyToClipboard text={bastionFqdn} onCopy={() => onFQDHCopy()}>
              <Icon name='clipboard' link />
            </CopyToClipboard>
          }
        />
        {bastionFqdn}
      </Card.Content>
    </Card>
  );
});
