import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'semantic-ui-react';

import { Lab } from 'types';

import { setLabDetails } from 'actions/actions';

import { RootState } from 'Reducers/contactReducer';

import './styles.less';

type Props = {
  readonly lab: Lab;
};

export const LabSidebarContent: React.FC<Props> = memo(({ lab }) => {
  const dispatch = useDispatch();
  const labState = useSelector((state: RootState) => state.labState.lab);
  return (
    <div>
      <div className='content-div'>Contents</div>
      {lab.labTemplate.labGuideUrls.map((doc, index) => (
        <Card
          key={index}
          link
          className='content-card'
          fluid
          onClick={() =>
            dispatch(
              setLabDetails({
                ...labState,
                lab: {
                  ...lab,
                  progress: {
                    currentPage: index,
                    totalPages: lab.progress.totalPages
                  }
                },
                isContent: true
              })
            )
          }
        >
          <Card.Description className='content-font'>
            {doc.name}
          </Card.Description>
        </Card>
      ))}
    </div>
  );
});
