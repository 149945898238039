export const host = window.location.protocol + '//' + window.location.host;

export const JuniperLabGuideDoc = () => {
  return host + '/labguide/Cloudlabs/4.2.1/lab1-junos/lab1-junos-0_intro.html';
};

export const GettingStartedLabGuideDoc = () => {
  return host + '/labguide/Cloudlabs/4.2.1/index.html';
};

export const MultiLabGuideDoc = () => {
  return host + '/labguide/Cloudlabs/4.2.1/lab1-multi/lab1-multi-0_intro.html';
};

export const FreeformLabGuideDoc = () => {
  return host + '/labguide/Cloudlabs/4.2.1/labff/labff-0_intro.html';
};

export const FreeformRALabGuideDoc = () => {
  return host + '/labguide/Cloudlabs/4.2.1/labff_ra/lab_ff_ra_0_intro.html';
};

export const ApiLabGuideDoc = () => {
  return host + '/labguide/Cloudlabs/4.2.1/labapi/labapi-0.html';
};

export const TerraformJunosLabGuideDoc = () => {
  return host + '/labguide/Cloudlabs/4.2.1/lab-terraform/labTF-1-intro.html';
};

export const reloadData = () => {
  setTimeout(() => {
    window.location.reload();
  }, 1000);
};

export const getBaseUrl = (): string => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
};
